import { Box } from '@chakra-ui/react';
import React from 'react';

import { WLDivider, WLGallery, WLLink, WLText } from '../../design-library';
import { DividerColorScheme } from '../../design-library/Divider';
import IconAdapter from '../IconAdapter';

interface LearnMoreLinksProps {
  path: string;
}

type LinkDefn = {
  label: string;
  href: string;
  icon: string;
};

const LifeInsurance: LinkDefn = {
  label: 'Life Insurance',
  href: '/insurance/life-insurance/',
  icon: 'LifeInsurance',
};
const Retirement: LinkDefn = {
  label: 'Retirement Plans',
  href: '/retirement/',
  icon: 'RetirementPlans',
};
const Extras: LinkDefn = {
  label: 'Extras',
  href: '/extras/',
  icon: 'Extras',
};
const About: LinkDefn = {
  label: 'About Us',
  href: '/about/',
  icon: 'AboutUs',
};

const LearnMoreLink: React.FC<LinkDefn> = ({ label, href, icon }) => {
  return (
    <WLLink
      to={href}
      arrow={false}
      width="full"
      variant="plain"
      flexDirection="column"
    >
      <IconAdapter name={icon} boxSize="60px" />
      <WLText as="b" fontSize="14px">
        {label}
      </WLText>
    </WLLink>
  );
};

// TODO CMS Candidate
//      Due to the complex path-matching here this isn't being extracted to Contentful at this time. Ideally this would be
//      CMS-managed, but would likely have to be managed on a page-by-page basis because it would be difficult to build a
//      pattern-match algorithm in the CMS that would be maintainable by a non-technical individual

const LearnMoreLinks: React.FC<LearnMoreLinksProps> = ({ path }) => {
  const links = React.useMemo(() => {
    const links: LinkDefn[] = [];
    // Link 1
    if (
      [
        '/annuities',
        '/retirement',
        '/investments',
        '/extras',
        '/about',
        '/contact',
      ].some((val) => path.startsWith(val))
    ) {
      links.push(LifeInsurance);
    } else if (['/insurance'].some((val) => path.startsWith(val))) {
      links.push(Retirement);
    }

    // Link 2
    if (
      ['/insurance', '/retirement', '/investments'].some((val) =>
        path.startsWith(val)
      )
    ) {
      links.push(Extras);
    } else if (
      ['/extras', '/about', '/contact'].some((val) => path.startsWith(val)) &&
      !path.startsWith('/investments')
    ) {
      links.push(Retirement);
    }

    // Link 3
    if (
      [
        '/insurance',
        '/retirement',
        '/investments',
        '/extras',
        '/about',
        '/contact',
      ].some((val) => path.startsWith(val))
    ) {
      links.push(About);
    }

    return links;
  }, [path]);

  if (links.length === 0) {
    return null;
  }

  return (
    <Box width="full" paddingY={4}>
      <WLDivider colorScheme={DividerColorScheme.MIXED} as="h5">
        Learn More
      </WLDivider>
      <WLGallery layout="FIXED" columns={[1, 1, links.length]} spacing={8}>
        {links.map((link, index) => (
          <LearnMoreLink key={index} {...link} />
        ))}
      </WLGallery>
    </Box>
  );
};

export default LearnMoreLinks;
